import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import striptags from 'striptags';
import Head from 'next/head';
import { withTranslation } from 'lib/i18n';
import CLUB_PROP_TYPES from 'lib/prop-types/club';
import { getCurrentClub } from 'store/clubs/selectors';
import CanonicalLink from './canonical';

const SeoHead = ({
  club,
  description,
  descriptionTranslationKey,
  route,
  routeParams,
  titleTranslationKey,
  translationValues,
  t,
  title,
}) => {
  const values = { ...translationValues, clubName: club.name };
  const pageTitle = title || t(titleTranslationKey, values);
  let metaDescription = '';
  if (description) {
    metaDescription = striptags(description);
  }

  if (descriptionTranslationKey) {
    metaDescription = t(descriptionTranslationKey, { ...translationValues, clubName: club.name });
  }

  return (
    <Head>
      <title key="title">{pageTitle}</title>
      {metaDescription.length > 1 && (
        <meta
          name="description"
          content={
            metaDescription.length > 160 ? metaDescription.substring(0, 160) : metaDescription
          }
          key="metaDescription"
        />
      )}
      <CanonicalLink club={club} route={route} params={routeParams} />
    </Head>
  );
};

SeoHead.defaultProps = {
  description: null,
  descriptionTranslationKey: null,
  routeParams: {},
  title: null,
  titleTranslationKey: null,
  translationValues: {},
};

SeoHead.propTypes = {
  club: CLUB_PROP_TYPES.isRequired,
  description: PropTypes.string,
  descriptionTranslationKey: PropTypes.string,
  route: PropTypes.string.isRequired,
  routeParams: PropTypes.shape(),
  title: PropTypes.string,
  titleTranslationKey: PropTypes.string,
  translationValues: PropTypes.shape(),
  t: PropTypes.func.isRequired,
};

const mapStateToProps = (state, { club }) => ({
  club: club || getCurrentClub(state),
});

export default withTranslation(['common'])(connect(mapStateToProps)(SeoHead));
