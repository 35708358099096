import { PLACEHOLDER_AVATAR } from 'lib/club-theme/avatar';
import PageData from './data';

class MatchCentreParser {
  constructor(matchCentreData, userAccessLevel) {
    this.matchCentreData = matchCentreData;
    this.userAccessLevel = userAccessLevel;
  }

  parseMatchCentreData() {
    const { key, data } = this.matchCentreData;
    const parsedData = {
      ...data,
      lineup: this.parseLineup(),
      album: this.parseAlbum(),
    };
    const accessLevel = this.isFullyPublic() ? 'public' : 'mixed';
    return new PageData(key, parsedData, accessLevel);
  }

  isFullyPublic() {
    return this.isAlbumPublic() && this.isLineupPublic();
  }

  isAlbumPublic() {
    const { album } = this.matchCentreData.data;
    if (!album) {
      return true;
    }

    return album.visibility === 'public';
  }

  isLineupPublic() {
    const { lineup } = this.matchCentreData.data;
    if (!lineup) {
      return true;
    }

    const {
      profile_visibility: profileVisibility,
      selection_visibility: selectionVisibility,
    } = lineup;
    return profileVisibility === 'public' && selectionVisibility === 'public';
  }

  parseAlbum() {
    const { album } = this.matchCentreData.data;
    if (!album) {
      return null;
    }

    if (this.canViewAlbum()) {
      return {
        ...album,
        accessDenied: false,
      };
    }

    return {
      accessDenied: true,
    };
  }

  parseLineup() {
    const { lineup } = this.matchCentreData.data;
    if (!lineup) {
      return lineup;
    }

    if (!this.canViewSelection()) {
      return {
        accessDenied: true,
      };
    }

    if (this.canViewProfiles()) {
      return {
        ...lineup,
        accessDenied: false,
      };
    }

    const removeAvatars = (profiles) => {
      if (!Array.isArray(profiles)) {
        return profiles;
      }

      return profiles.map((profile) => ({
        ...profile,
        avatar: PLACEHOLDER_AVATAR,
      }));
    };

    return {
      ...lineup,
      accessDenied: false,
      players: removeAvatars(lineup.players),
      substitutes: removeAvatars(lineup.substitutes),
      opponents: removeAvatars(lineup.opponents),
    };
  }

  canViewAlbum() {
    const {
      album: { visibility: albumVisibility },
    } = this.matchCentreData.data;

    return albumVisibility === 'public' || albumVisibility === this.userAccessLevel;
  }

  canViewSelection() {
    const {
      lineup: { selection_visibility: selectionVisibility },
    } = this.matchCentreData.data;

    return selectionVisibility === 'public' || selectionVisibility === this.userAccessLevel;
  }

  canViewProfiles() {
    const {
      lineup: { profile_visibility: profileVisibility },
    } = this.matchCentreData.data;
    return profileVisibility === 'public' || profileVisibility === this.userAccessLevel;
  }
}

export default MatchCentreParser;
