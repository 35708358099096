import logger from 'lib/logger';

class PageAccessControl {
  constructor(clubId, request) {
    this.accessLevel = null;
    this.clubId = clubId;
    this.request = request;
  }

  async getAccessLevel() {
    if (this.accessLevel) {
      // Already loaded accessLevel
      return this.accessLevel;
    }

    const checkToken = await import('lib/api-proxy/check-token');
    const token = await checkToken.default(this.request);
    if (!token) {
      // User has no token (not logged in), they get public access only.
      this.accessLevel = 'public';
      return this.accessLevel;
    }

    // Load Access level from the API
    this.accessLevel = await this.getAccessLevelFromAPI(token);
    return this.accessLevel;
  }

  async canUserAccessPage(pageData) {
    if (pageData.accessLevel === 'public') {
      // Public page, everyone can access, no need for further checks
      return true;
    }

    const userAccessLevel = await this.getAccessLevel();
    return pageData.accessLevel === userAccessLevel;
  }

  async getAccessLevelFromAPI(jwt) {
    try {
      const apiUrl = `v2/club-website/${this.clubId}/access`;
      logger.info('Checking user access via API', { apiUrl });
      const headers = {
        Authorization: `Bearer ${jwt}`,
      };

      const { makeAPIRequest } = await import('lib/api-proxy');
      const response = await makeAPIRequest(apiUrl, { headers });
      if (response.status !== 200) {
        logger.error(`User access via API returned with status: ${response.status}`);
        return 'public';
      }

      const data = await response.json();
      logger.info(`Access level returned by API: ${data.access_level}`);
      return data.access_level || 'public';
    } catch (error) {
      logger.error('Error with user access API check', { error });
      return 'public';
    }
  }
}

export default PageAccessControl;
